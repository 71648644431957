var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length < 1)?_c('div',{staticClass:"v-data-table__empty-wrapper align-center"},[_vm._v(" No logs of unpacking the archive ")]):_c('div',{staticClass:"mt-3 mb-3"},[_c('v-data-table',{staticClass:"elevation-1 unpacking-log",attrs:{"headers":_vm.headers,"items":_vm.getItems,"items-per-page":10,"sort-by":"started_at","sort-desc":""},scopedSlots:_vm._u([{key:"item.srv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.srv.name)+" "),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.srv.ip[0])+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.unzip_without_changes !== true)?_c('div',{staticClass:"grey--text small pa-2"},[_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"green--text mr-3"},[_vm._v("Affiliate:")]),_vm._v(" "+_vm._s(item.affiliate)+" ")]),_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"green--text mr-3"},[_vm._v("Token:")]),_vm._v(" "+_vm._s(item.token)+" ")]),_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"green--text mr-3"},[_vm._v("CDN:")]),_vm._v(" "+_vm._s(item.cdn)+" ")]),_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"green--text mr-3"},[_vm._v("Base:")]),_vm._v(" "+_vm._s(item.base)+" ")])]):_c('div',[_c('span',{staticClass:"small grey--text mr-3"},[_vm._v(_vm._s(_vm.$t('Unpacked only')))])])]}},{key:"item.cdn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cdn)+" ")]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_c('table-date',{attrs:{"date":item.started_at}})]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_c('table-date',{attrs:{"date":item.finished_at}})]}},{key:"item.domains",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},_vm._l((item.domains),function(domain,key){return _c('v-chip',{key:key,staticClass:"ma-1",attrs:{"small":""},on:{"dblclick":function($event){return _vm.openWindow(domain)}}},[_vm._v(" "+_vm._s(domain)+" ")])}),1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }