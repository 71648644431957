<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          @dragover.prevent="dragover"
          @drop="uploadFiles"
        >
          <table-data
            :title="title"
            :table-headers="tableHeaders"
            :form-scheme="formScheme"
            :resource="resource"
            :allow-default-actions="true"
            :expanded.sync="expanded"
            :show-expand="$can('tasks.unpacking.view') ? showExpand : false"
            :single-expand="singleExpand"
            @inputChange="handleChange"
          >
            <template #top-action-buttons>
              <div class="text-no-wrap">
                <input
                  id="files"
                  ref="files"
                  type="file"
                  class="d-none"
                  multiple
                  accept=".zip"
                  @change="uploadSelectedFiles"
                >
                <v-btn
                  v-can="'tasks.upload.upload'"
                  color="primary mr-3"
                  small
                  @click="$refs.files.click()"
                >
                  Select archives
                </v-btn>
                <span
                  v-can="'tasks.upload.upload'"
                  class="small grey--text ml-5 text-decoration-underline"
                >Drag landing page archive here!</span>
                <span
                  v-can="'tasks.upload.upload'"
                  class="small red--text ml-5 text-decoration-underline"
                >Max size = 99Mb</span>
              </div>
            </template>

            <template #[`item.originalname`]="{ item }">
              {{ item.file_details.originalname }}
            </template>

            <template #[`item.user`]="{ item }">
              {{ item.user !== undefined ? item.user.name : '—' }}
            </template>

            <template #[`item.size`]="{ item }">
              {{ (item.file_details.size / 1024 / 1024).toFixed(3) }}Mb
            </template>

            <template #action-buttons="{ item }">
              <table-btn
                :item="item"
                :tooltip-text="$t('tasks.upload.table.btn.unpacking')"
                :icon="`package-variant`"
                :fn="unpacking"
              />
              <table-btn
                :item="item"
                :tooltip-text="$t('tasks.upload.table.btn.details')"
                :icon="`file-eye-outline`"
                :fn="getFileDetails"
              />
            </template>

            <!--            <template v-slot:expanded-item="{ headers, item }">-->
            <!--              <td-->
            <!--                v-if="Object.keys(item.log || {}).length > 0"-->
            <!--                :colspan="headers.length"-->
            <!--              >-->
            <!--                <v-row>-->
            <!--                  <v-col cols="12">-->
            <!--                    <v-divider />-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </td>-->
            <!--              <td-->
            <!--                v-else-->
            <!--                :colspan="headers.length"-->
            <!--                class="v-data-table__empty-wrapper"-->
            <!--              >-->
            <!--                Loading... Please wait-->
            <!--              </td>-->
            <!--            </template>-->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <component
                  :is="detailsComponent"
                  :key="detailsComponentKey"
                  :params="item"
                />
              </td>
            </template>
          </table-data>
          <v-dialog
            v-model="isProgressShow"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="grey"
              dark
            >
              <v-card-text
                v-for="(file, key) of getFiles"
                :key="key"
              >
                <div class="pt-3">
                  {{ file.name }}
                  <v-progress-linear
                    color="white"
                    class="mb-0"
                    :value="file.progress"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <dialogs-unpacking
            ref="unpackingDialogForm"
            @close="unpackingDialogClose"
          />
          <v-overlay
            v-can="'tasks.upload.upload'"
            :absolute="absolute"
            :value="overlay"
            @dragenter.prevent="dragenter"
          >
            <v-btn
              color="primary"
              x-large
              @click="overlay = false"
            >
              Close uploader
            </v-btn>
          </v-overlay>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogsUnpacking from './dialogs/Unpacking'
import UnpackingDetails from './components/UnpackingDetails'

export default {
  name: 'ArchiveUpload',
  components: {
    DialogsUnpacking,
    UnpackingDetails
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'tasks.upload',
    editedItem: {},
    files: [],
    filesProgress: [],
    progress: 0,
    dragenterClass: '',
    showDragBox: false,
    //
    detailsComponent: UnpackingDetails,
    detailsComponentKey: 0,
    //
    unpackingDialog: false,
    unpackingEditedItem: {},
    //
    absolute: true,
    overlay: false,
    //
    expanded: [],
    showExpand: true,
    singleExpand: true
  }),
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tasks.upload.meta.title')} — %s`
    }
  },
  computed: {
    isProgressShow() {
      return this.$store.getters['tasksUpload/getFiles'].length > 0
    },
    getFiles() {
      return this.$store.getters['tasksUpload/getFiles']
    },
    getCurrentUnpackingLog() {
      return this.$store.getters['tasksUnpacking/getUnpackingLog']
    }
  },
  watch: {
    isProgressShow(v) {
      this.overlay = v === true
    },
    getFiles(v) {},
    getCurrentUnpackingLog(val) {}
  },
  mounted () {
    this.title = this.$t('tasks.upload.title')

    this.tableHeaders = [
      {
        text: this.$t('tasks.upload.table.archiveName'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('Owner'),
        align: 'start',
        value: 'user'
        // width: '150px'
      },
      {
        text: this.$t('tasks.upload.table.archiveSize'),
        value: 'size',
        width: '100px',
        align: 'center'
      }
    ]

    this.formScheme = {}

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('Team'),
        value: 'team'
      }, 1)
    }
  },
  methods: {
    handleChange (changes = []) {
      this.editedItem = changes
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('tasks.upload.tableForm.validation.fieldIsRequired')
      }
      return !!value || this.$t('tasks.upload.tableForm.validation.fieldIsRequired')
    },
    async uploadFiles(e) {
      e.preventDefault()
      this.overlay = true
      const droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach((f) => {
        this.files.push(f)
      })
      e.target.value = null
      this.$store.commit('tasksUpload/setFiles', this.files)
      this.files.map((f) => {
        this.$store.dispatch('tasksUpload/commonUpload', { f })
      })
    },
    async uploadSelectedFiles(e) {
      e.preventDefault()
      this.overlay = true
      const droppedFiles = e.target.files
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach((f) => {
        const filesize = ((f.size / 1024) / 1024).toFixed(4)
        if (filesize > 99) {
          this.overlay = false
          this.$toast.error('Max file size 99MB')
          return false
        }
        this.files.push(f)
      })
      this.$store.commit('tasksUpload/setFiles', this.files)
      this.files.map((f) => {
        this.$store.dispatch('tasksUpload/commonUpload', { f })
      })
    },
    dragenter(evt, item) {
      evt.preventDefault()
      this.overlay = true
    },
    dragover(evt, item) {
      evt.preventDefault()
      this.overlay = true
    },
    async getFileDetails(item) {
      await this.$store.dispatch('dialog/dialog', {
        show: true,
        title: this.$t('tasks.upload.table.btn.details'),
        contentType: 'code',
        content: JSON.stringify(item.file_details, null, 2),
        maxWidth: 500,
        maxHeight: 800
      })
    },
    async unpacking(item) {
      await this.$refs.unpackingDialogForm.open(item)
      this.expandRow(item)
    },
    unpackingDialogClose() {
      this.unpackingDialog = false
      this.unpackingEditedItem = {}
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    }
  }
}
</script>

<style lang="scss">

</style>
