<template>
  <div
    v-if="items.length < 1"
    class="v-data-table__empty-wrapper align-center"
  >
    No logs of unpacking the archive
  </div>
  <div
    v-else
    class="mt-3 mb-3"
  >
    <v-data-table
      :headers="headers"
      :items="getItems"
      :items-per-page="10"
      sort-by="started_at"
      sort-desc
      class="elevation-1 unpacking-log"
    >
      <template #[`item.srv`]="{ item }">
        {{ item.srv.name }}
        <div class="grey--text small">
          {{ item.srv.ip[0] }}
        </div>
      </template>
      <template #[`item.details`]="{ item }">
        <div
          v-if="item.unzip_without_changes !== true"
          class="grey--text small pa-2"
        >
          <div class="mr-3">
            <span class="green--text mr-3">Affiliate:</span> {{ item.affiliate }}
          </div>
          <div class="mr-3">
            <span class="green--text mr-3">Token:</span> {{ item.token }}
          </div>
          <div class="mr-3">
            <span class="green--text mr-3">CDN:</span> {{ item.cdn }}
          </div>
          <div class="mr-3">
            <span class="green--text mr-3">Base:</span> {{ item.base }}
          </div>
        </div>
        <div v-else>
          <span class="small grey--text mr-3">{{ $t('Unpacked only') }}</span>
        </div>
      </template>
      <template #[`item.cdn`]="{ item }">
        {{ item.cdn }}
      </template>
      <template #[`item.started_at`]="{ item }">
        <table-date
          :date="item.started_at"
        />
      </template>
      <template #[`item.finished_at`]="{ item }">
        <table-date
          :date="item.finished_at"
        />
      </template>
      <template #[`item.domains`]="{ item }">
        <div class="pa-2">
          <v-chip
            v-for="(domain, key) in item.domains"
            :key="key"
            class="ma-1"
            small
            @dblclick="openWindow(domain)"
          >
            {{ domain }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'UnpackingDetails',
  props: {
    params: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    more: {}
  }),
  computed: {
    getItems() {
      return this.items
    }
  },
  watch: {
    params(val) {}
  },
  async mounted () {
    this.items = await this.getUnpackingLog(this.params.id) || []

    this.$eventBus.$on('updateUnpacking', (data) => {
      if (Object.keys(data).length < 1) {
        return false
      }
      this.items = data
    })

    // this.items = this.params.unpacking
    this.headers = [
      {
        text: 'Server',
        value: 'srv'
      },
      {
        text: 'Details',
        value: 'details'
      },
      {
        text: 'Task state',
        value: 'status_text'
      },
      {
        text: 'Domain',
        value: 'domains'
      },
      {
        text: 'Started',
        value: 'started_at',
        align: 'center'
      },
      {
        text: 'Finished',
        value: 'finished_at',
        align: 'center'
      }
    ]
  },
  methods: {
    async getUnpackingLog(item) {
      let parentId
      if (typeof item === 'object') {
        parentId = item._id
      } else if (typeof item === 'string') {
        parentId = item
      }
      const response = await this.$store.dispatch('tasksUnpacking/getUnpackingLog', { parentId }) || []
      return response
    },
    rowClass(classes) {
      return classes
    },
    openWindow (domain) {
      window.open(`http://${domain}`, '_blank')
    }
  }
}
</script>
