<template>
  <dialog-form
    ref="unpackingDialog"
    :key="k"
    :dialog="dialog"
    :form-title="formTitle"
    :form-scheme.sync="formScheme"
    :save-loading="saveLoading"
    :edited-item="currentItem"
    :submit-text="`Unpacking`"
    @changeEditedItem="changeEditedItem"
    @save="unpackingAction"
    @close="close"
  />
</template>

<script>
export default {
  name: 'Unpacking',
  data() {
    return {
      dialog: false,
      formScheme: {},
      saveLoading: false,
      currentItem: {},
      defaultValue: {
        base: '/',
        cdn: 'techforce.cloud'
      },
      inputData: {},
      formTitle: '',
      k: 0
    }
  },
  watch: {
    dialog (v) {

    }
  },
  async mounted () {
    this.formTitle = this.$t('tasks.upload.unpacking.formTitle')
    // this.currentItem = Object.assign({}, this.editedItem)
    this.formScheme = {
      domains: {
        type: 'combobox',
        label: this.$t('tasks.upload.unpacking.domains'),
        createRules: 'req|min:1',
        updateRules: [],
        chips: true,
        multiple: true,
        // rows: 1,
        // 'auto-grow': true,
        md: 12,
        hint: this.$t('tasks.upload.unpacking.hint'),
        // appendIcon: 'sync',
        // appendCopyBtn: true,
        // appendClickFn: async (e, t) => {},
        clearable: true
      },
      token: {
        type: 'text',
        label: this.$t('tasks.upload.unpacking.token'),
        createRules: [],
        md: 6
      },
      affiliate: {
        type: 'text',
        label: this.$t('tasks.upload.unpacking.affiliate'),
        createRules: [],
        md: 6
      },
      base: {
        type: 'text',
        label: this.$t('tasks.upload.unpacking.base'),
        createRules: [],
        md: 2
      },
      cdn: {
        type: 'text',
        label: this.$t('tasks.upload.unpacking.cdn'),
        prefix: 'https://',
        suffix: '{/css/app.css,/js/app.js}',
        createRules: [],
        md: 10
      },
      unzipWithoutChanges: {
        type: 'switch',
        label: this.$t('Unpacking without changes'),
        // createRules: 'req',
        md: 6,
        fn: (i, e, v) => {
          this.switcherFields(v)
          this.currentItem.base = v ? '' : this.defaultValue.base
          this.currentItem.cdn = v ? '' : this.defaultValue.cdn
          this.k += 1
        }
      },
      compressHtml: {
        type: 'switch',
        label: this.$t('tasks.upload.unpacking.compressHtml'),
        // createRules: 'req',
        md: 6
      }
    }
  },
  methods: {
    changeEditedItem(changes) {
      if (changes.domains instanceof Array && typeof changes.domains[0] === 'string') {
        if (/[\s,]/.test(changes.domains[0])) {
          changes.domains = changes.domains[0].split(/[\s,]/)
          changes.domains = changes.domains.map((d) => d.trim()).filter(Boolean)
        }
      }
      this.currentItem = Object.assign({}, this.currentItem, changes)
      return changes
    },
    async unpackingAction(evn) {
      try {
        const v = evn.$refs.form.validate()
        if (v) {
          this.saveLoading = true
          await this.$store.dispatch('tasksUnpacking/unpacking', { parentId: this.inputData.id, data: this.currentItem })
          await this.$store.dispatch('adminLayout/toggleDialogEventLog', true)
          this.saveLoading = false
          this.close()
        }
      } catch (e) {
        console.log(e)
      }
    },
    open(item) {
      this.dialog = true
      this.inputData = item
      this.switcherFields(false)
      this.currentItem = {
        base: this.defaultValue.base,
        cdn: this.defaultValue.cdn
      }
    },
    close() {
      this.dialog = false
      this.$emit('close', this)
    },
    switcherFields(v) {
      this.formScheme.cdn.disabled = v
      this.formScheme.base.disabled = v
      this.formScheme.affiliate.disabled = v
      this.formScheme.token.disabled = v
      this.formScheme.compressHtml.disabled = v
    }
  }
}
</script>

<style lang="scss">

</style>
