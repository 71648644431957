var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{on:{"dragover":function($event){$event.preventDefault();return _vm.dragover.apply(null, arguments)},"drop":_vm.uploadFiles}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"allow-default-actions":true,"expanded":_vm.expanded,"show-expand":_vm.$can('tasks.unpacking.view') ? _vm.showExpand : false,"single-expand":_vm.singleExpand},on:{"update:expanded":function($event){_vm.expanded=$event},"inputChange":_vm.handleChange},scopedSlots:_vm._u([{key:"top-action-buttons",fn:function(){return [_c('div',{staticClass:"text-no-wrap"},[_c('input',{ref:"files",staticClass:"d-none",attrs:{"id":"files","type":"file","multiple":"","accept":".zip"},on:{"change":_vm.uploadSelectedFiles}}),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('tasks.upload.upload'),expression:"'tasks.upload.upload'"}],attrs:{"color":"primary mr-3","small":""},on:{"click":function($event){return _vm.$refs.files.click()}}},[_vm._v(" Select archives ")]),_c('span',{directives:[{name:"can",rawName:"v-can",value:('tasks.upload.upload'),expression:"'tasks.upload.upload'"}],staticClass:"small grey--text ml-5 text-decoration-underline"},[_vm._v("Drag landing page archive here!")]),_c('span',{directives:[{name:"can",rawName:"v-can",value:('tasks.upload.upload'),expression:"'tasks.upload.upload'"}],staticClass:"small red--text ml-5 text-decoration-underline"},[_vm._v("Max size = 99Mb")])],1)]},proxy:true},{key:"item.originalname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.file_details.originalname)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user !== undefined ? item.user.name : '—')+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.file_details.size / 1024 / 1024).toFixed(3))+"Mb ")]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('tasks.upload.table.btn.unpacking'),"icon":"package-variant","fn":_vm.unpacking}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('tasks.upload.table.btn.details'),"icon":"file-eye-outline","fn":_vm.getFileDetails}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{key:_vm.detailsComponentKey,tag:"component",attrs:{"params":item}})],1)]}}],null,true)}),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.isProgressShow),callback:function ($$v) {_vm.isProgressShow=$$v},expression:"isProgressShow"}},[_c('v-card',{attrs:{"color":"grey","dark":""}},_vm._l((_vm.getFiles),function(file,key){return _c('v-card-text',{key:key},[_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(file.name)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"white","value":file.progress}})],1)])}),1)],1),_c('dialogs-unpacking',{ref:"unpackingDialogForm",on:{"close":_vm.unpackingDialogClose}}),_c('v-overlay',{directives:[{name:"can",rawName:"v-can",value:('tasks.upload.upload'),expression:"'tasks.upload.upload'"}],attrs:{"absolute":_vm.absolute,"value":_vm.overlay},on:{"dragenter":function($event){$event.preventDefault();return _vm.dragenter.apply(null, arguments)}}},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Close uploader ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }